import React, { FC, useCallback, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as Checkmark } from "@libs/assets/icons/check.svg";
import { ReactComponent as SignOutIcon } from "@libs/assets/icons/sign-out.svg";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { UserAvatar } from "components/UI/UserAvatar";
import { normal14, semibold14 } from "assets/styles/textSize";
import { paths } from "router/paths";
import { getUserAccounts } from "api/user/queries";
import { postAuthChannelMessage } from "hooks/useAuthChannelListeners";
import { FamilyMemberUserAccount } from "api/user/types";
import { mapToFamilyAccounts, useSwitchAccount } from "hooks/useSwitchAccount";

const MenuContent: FC<{
  onSwitchAccount: (account: FamilyMemberUserAccount) => void;
  patientId: number;
}> = ({ onSwitchAccount, patientId }) => {
  const { t } = useTranslation();

  const [getUserAccountsQuery] = useApiQueries([getUserAccounts()]);
  const familyUserAccounts = useMemo(
    () => mapToFamilyAccounts(getUserAccountsQuery.data),
    [getUserAccountsQuery.data]
  );

  return (
    <div className="min-w-[280px]">
      <div className="max-h-72 overflow-y-auto">
        {familyUserAccounts.map((item) => {
          const selected = patientId === item.id;

          return (
            <button
              key={item.id}
              type="button"
              className={`
                flex
                gap-x-2
                w-full
                items-center
                hover:bg-slate-100
                py-3
                px-4
              `}
              onClick={() => {
                onSwitchAccount(item);
              }}
            >
              <div className="w-8 h-8">
                <UserAvatar thumbnail={item.avatar?.thumbnail} />
              </div>
              <div
                className={cx(
                  "flex-1 text-left",
                  selected ? semibold14 : normal14,
                  selected ? "text-greyDark" : "text-greyMedium"
                )}
              >
                <div>{item.name.fullDisplayName}</div>
                <div className="text-xs">{item.practice.name}</div>
              </div>

              <div>{selected && <Checkmark className="text-green-500" />}</div>
            </button>
          );
        })}
      </div>
      <div className="px-4 py-2">
        <ButtonInternalLink className="flex w-full justify-center items-center" to={paths.signOut()}>
          <SignOutIcon className="mr-1" />
          {t("app.page.labels.signOut")}
        </ButtonInternalLink>
      </div>
    </div>
  );
};

export const AccountsMenu: FC<{ thumbnail: string | undefined }> = ({ thumbnail }) => {
  const menu = useBoolean(false);
  const prefetchAccountsRef = useRef(false);

  // UX optimization so users don't see a loading symbol
  const [getUserAccountsQuery] = useApiQueries([getUserAccounts({ queryOptions: { enabled: false } })]);

  const fetchAccounts = getUserAccountsQuery.refetch;
  const handleMouseEnter = useCallback(() => {
    if (!prefetchAccountsRef.current) {
      prefetchAccountsRef.current = true;
      fetchAccounts();
    }
  }, [fetchAccounts]);

  const { id: patientId } = useAccount();

  const handleAccountSwitched = useCallback((account: FamilyMemberUserAccount) => {
    postAuthChannelMessage({
      type: "selectAccount",
      userId: account.id,
    });

    window.location.assign(paths.home());
  }, []);

  const switchAccount = useSwitchAccount({
    onAccountSelected: handleAccountSwitched,
  });

  const handleSwitchAccount = useCallback(
    (account: FamilyMemberUserAccount) => {
      switchAccount(account);
      menu.off();
    },
    [menu, switchAccount]
  );

  return (
    <div className="flex items-center">
      <ButtonMenu
        placement="bottom-end"
        isOpen={menu.isOn}
        onRequestOpen={menu.on}
        className="z-20"
        menuContent={<MenuContent patientId={patientId} onSwitchAccount={handleSwitchAccount} />}
        onRequestClose={menu.off}
      >
        {(props) => (
          <button {...props} type="button" className="h-10 w-10" onMouseEnter={handleMouseEnter}>
            <UserAvatar thumbnail={thumbnail} />
          </button>
        )}
      </ButtonMenu>
    </div>
  );
};
