import { filenameToMimeType } from "@libs/utils/mimeTypes";
import { isNullish } from "@libs/utils/types";

export const getHeightAndWidthFromDataUrl = (dataURL: string): Promise<{ height: number; width: number }> => {
  return new Promise<{ height: number; width: number }>((resolve) => {
    const img = new Image();

    img.addEventListener("load", () => {
      resolve({
        height: img.height,
        width: img.width,
      });
    });
    img.src = dataURL;
  });
};
export const dataURLtoFile = (dataurl: string, filename: string) => {
  const [metadata, data] = dataurl.split(",");
  const match = metadata?.match(/:(.*?);/);

  if (!match || match.length < 1 || isNullish(data)) {
    throw new Error("Invalid dataUrl");
  }

  const mime = match[1];
  const bstr = window.atob(data);
  let n = bstr.length;
  const u8arry = new Uint8Array(n);

  while (n--) {
    u8arry[n] = bstr.codePointAt(n) as number;
  }

  return new File([u8arry], filename, { type: mime });
};
export const readFileToURL = (file: File): Promise<string> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();

    reader.addEventListener("load", (e) => res(e.target?.result as string));
    reader.addEventListener("onerror", (e) => rej(e));
    reader.readAsDataURL(file);
  });
};

export const blobToFile = (theBlob: Blob, fileName: string) => {
  return new File([theBlob], fileName, { lastModified: Date.now(), type: filenameToMimeType(fileName) });
};

export const downloadBlob = (blob: Blob, filename: string) => {
  const imageUrl = URL.createObjectURL(blob);
  const tag = document.createElement("a");

  tag.href = imageUrl;
  tag.download = filename;
  document.body.append(tag);
  tag.click();
  tag.remove();

  // Clean up memory.
  window.URL.revokeObjectURL(imageUrl);
};

export const downloadUrl = async (url: string, filename: string) => {
  // If we hosted these images from archy.com, could just do `<a href={image.url} download />` but we have to load into a blob:
  const resp = await fetch(url);
  const blob = await resp.blob();

  downloadBlob(blob, filename);
};

export const fetchImageAsBase64 = async (url: string, options?: RequestInit) => {
  const resp = await fetch(url, options);
  const imgBlob = await resp.blob();

  return new Promise<string>((res, rej) => {
    const reader = new FileReader();

    reader.addEventListener("load", (e) => res(e.target?.result as string));
    reader.addEventListener("onerror", (e) => rej(e));
    reader.readAsDataURL(imgBlob);
  });
};
