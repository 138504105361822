import React from "react";
import { FormSelectResponseVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { PatientResponses, ResponseChangedCallback } from "components/PatientForms/hooks/usePatientResponses";
import { YesNoQuestion } from "components/PatientForms/FormElements/YesNoQuestion";
import { OTHER_ID } from "components/PatientForms/utils";
import { Invalidation } from "components/PatientForms/hooks/usePatientFormValidation";
import { ReadOnlyOptions } from "components/PatientForms/FormElements/ReadOnlyOptions";
import { PatientFormFieldTitle } from "components/PatientForms/FormElements/FormFieldTitle";

type Props = {
  edit: boolean;
  responsesById: PatientResponses;
  options: { label: string | React.ReactNode; value: string }[];
  onChangeResponse: ResponseChangedCallback;
  selectedOptions: Set<string>;
  uuid: string;
  title: string;
  invalidation?: Invalidation;
};

export const PatientFormSelectExplicitElement: React.FC<Props> = ({
  uuid,
  title,
  edit,
  responsesById,
  onChangeResponse,
  invalidation,
  options,
  selectedOptions,
}) => {
  const prevResponse = responsesById[uuid];
  const prevSelectResponse = prevResponse?.type === "SELECT" ? prevResponse : undefined;
  const error = invalidation?.error;

  return edit ? (
    <>
      <PatientFormFieldTitle title={title} required error={error} />
      {error && <div className="text-xs text-red-500">{error}</div>}
      <div className="flex flex-col gap-4">
        {options
          .filter((item) => item.value !== OTHER_ID)
          .map((option, i) => {
            const key = `${uuid}-${i}`;
            const optionError = invalidation?.options?.includes(option.value) ? error : undefined;

            return (
              <YesNoQuestion
                key={key}
                label={option.label}
                error={optionError}
                displayErrorMessage={false}
                selectedValue={prevSelectResponse?.responses[option.value]}
                onChange={(value) => {
                  const newResponse: FormSelectResponseVO = prevSelectResponse
                    ? { ...prevSelectResponse, responses: { ...prevSelectResponse.responses } }
                    : { type: "SELECT", responses: {} };

                  if (newResponse.responses[option.value] === value) {
                    delete newResponse.responses[option.value];
                  } else if (isDefined(value)) {
                    newResponse.responses = {
                      ...newResponse.responses,
                      [option.value]: value,
                    };
                  }

                  onChangeResponse(uuid, newResponse);
                }}
              />
            );
          })}
      </div>
    </>
  ) : (
    <ReadOnlyOptions
      selected={[...selectedOptions]}
      otherResponse={prevSelectResponse?.other}
      title={title}
    />
  );
};
