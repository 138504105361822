import { useTranslation } from "react-i18next";
import { ReactComponent as CheckIcon } from "@libs/assets/icons/check-circle-1-filled.svg";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { semibold16 } from "assets/styles/textSize";
import { Header } from "components/Main/Header/Header";

export const PaymentCompleteScreen: React.FC<{
  email: string;
  practice: PracticeInfoVO;
  children?: React.ReactNode;
}> = ({ email, practice, children }) => {
  const { t } = useTranslation();

  return (
    <div className="h-full bg-white">
      <Header practice={practice} />
      <div className="h-[50px]" />
      <div className="flex flex-col p-4 items-center">
        <div
          className={`
            flex
            flex-col
            items-center
            gap-6
            w-full
            text-center
            max-w-md
            overflow-y-auto
            pt-10
          `}
        >
          <div className={semibold16}>{t("Payment Complete!")}</div>
          <CheckIcon className="text-green-800 w-10 h-10" />

          <p>
            {t("pages.guestCheckout.receiptEmailed", {
              email,
            })}
          </p>

          {children}
        </div>
      </div>
    </div>
  );
};
