import { useMediaQuery } from "react-responsive";
// Returns current tailwind breakpoint for screen width

// https://tailwindcss.com/docs/screens
export type ScreenSize = "xs" | "sm" | "md" | "lg" | "xl" | "2xl";

const sizeMap: Record<ScreenSize, number> = {
  xs: 480,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "2xl": 1536,
};
const sizes: ScreenSize[] = ["xs", "sm", "md", "lg", "xl", "2xl"];

export const useResponsiveBreakpoint = (): {
  screenSize: ScreenSize;
  minWidth: number;
  screenMatches: Set<ScreenSize>;
} => {
  const values: Record<ScreenSize, boolean> = {
    xs: useMediaQuery({ minWidth: sizeMap.xs }),
    sm: useMediaQuery({ minWidth: sizeMap.sm }),
    md: useMediaQuery({ minWidth: sizeMap.md }),
    lg: useMediaQuery({ minWidth: sizeMap.lg }),
    xl: useMediaQuery({ minWidth: sizeMap.xl }),
    // eslint-disable-next-line @typescript-eslint/naming-convention
    "2xl": useMediaQuery({ minWidth: sizeMap["2xl"] }),
  };

  for (let i = sizes.length; i >= 0; i--) {
    const key = sizes[i];

    if (key && values[key]) {
      const screenSize = key;
      const sizeIndex = sizes.indexOf(screenSize);

      return {
        screenSize,
        screenMatches: new Set(sizes.filter((_, j) => j <= sizeIndex)),
        minWidth: sizeMap[screenSize],
      };
    }
  }

  return {
    screenSize: "xs",
    minWidth: sizeMap.xs,
    screenMatches: new Set(["xs"]),
  };
};
