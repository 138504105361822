import { ChangeEvent, InputHTMLAttributes, forwardRef, useMemo } from "react";
import { isDefined, isString } from "@libs/utils/types";
import { split } from "@libs/utils/split";

const canAddFirstSlash = /^[2-9]|12|11|10|0[1-9]$/;
const canAddSecondSlash = /^[4-9]|0[1-9]|[1-3]\d$/;
const MAX_DATE_STRING_LENGTH = 10;
const MAX_SLASHES = 2;
const MAX_MONTH_LENGTH = 2;
const MAX_DAY_LENGTH = 2;

export const FormattedDateInput = forwardRef<HTMLInputElement, InputHTMLAttributes<HTMLInputElement>>(
  ({ value, onChange, ...rest }, ref) => {
    const formattedValue = useMemo(() => {
      if (!isString(value)) {
        return value;
      }

      const parts = split(value, "/");
      const [month, day, year] = parts;

      // try to add a slash after the month part
      if (!isDefined(day) && month.length <= MAX_MONTH_LENGTH && canAddFirstSlash.test(month)) {
        return `${value}/`;
      }

      // try to add a slash after the day part
      if (isDefined(day) && !isDefined(year) && day.length <= MAX_DAY_LENGTH && canAddSecondSlash.test(day)) {
        return `${value}/`;
      }

      return value;
    }, [value]);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      let cleaned = e.target.value;

      if (!isString(formattedValue)) {
        onChange?.(e);
      } else if (formattedValue.endsWith("/") && `${formattedValue}/` === cleaned) {
        // If the user types a slash right after a one was injected ignore
        e.preventDefault();
      } else if (`${cleaned}/` === formattedValue) {
        // If the user hits backspace to delete a slash at the end of the input
        // also remove the preceding character that was automatically added
        e.target.value = cleaned.slice(0, -1);
        onChange?.(e);
      } else {
        // remove all values that aren't numbers or slashes
        // and remove excess characters
        cleaned = cleaned.replaceAll(/[^\d/]/g, "").slice(0, MAX_DATE_STRING_LENGTH);

        // strip excess slashes
        cleaned = cleaned
          .split("/")
          .slice(0, MAX_SLASHES + 1)
          .join("/");

        e.target.value = cleaned;

        onChange?.(e);
      }
    };

    return <input type="text" value={formattedValue} onChange={handleChange} {...rest} ref={ref} />;
  }
);
