import React from "react";
import { useTranslation } from "react-i18next";
import { View } from "@react-pdf/renderer";
import { half } from "@libs/utils/math";
import { Text } from "components/PatientForms/FormPDFElements/Text";
import { PDFCheckbox } from "components/PatientForms/FormPDFElements/PDFCheckbox";
import { OTHER_ID } from "components/PatientForms/utils";
import { PDFRadio } from "components/PatientForms/FormPDFElements/PDFRadio";

type Option = {
  label: string;
  value: string;
};
type Props = {
  responses?: Record<string, boolean>;
  options: Option[];
  isOtherSelected: boolean;
  otherResponse?: string;
  hasOtherOption: boolean;
  type?: "checkbox" | "radio";
};

export const PatientFormSelectInput: React.FC<Props> = ({
  options,
  isOtherSelected,
  otherResponse,
  hasOtherOption,
  responses,
  type = "checkbox",
}) => {
  const { t } = useTranslation();

  const chunked = React.useMemo(() => {
    const chunkedItems = [];

    for (let i = 0; i < half(options.length); i++) {
      const subset: (Option | null | undefined)[] = [options[i], options[i + half(options.length)] ?? null];

      chunkedItems.push(subset);
    }

    if (hasOtherOption) {
      const last = chunkedItems.at(-1);

      if (last) {
        if (last[1]) {
          chunkedItems.push([{ value: OTHER_ID, label: t("Other") }, null]);
        } else {
          last[1] = { value: OTHER_ID, label: t("Other") };
        }
      }
    }

    return chunkedItems;
  }, [hasOtherOption, options, t]);

  return (
    <>
      {chunked.map((row, i) => {
        return (
          <View style={{ flexDirection: "row" }} key={i}>
            {row.map((item, j) => {
              if (!item) {
                return <View key={j} style={{ flex: 1 }} />;
              }

              const checked = item.value === OTHER_ID ? isOtherSelected : responses?.[item.value];
              const itemProps = {
                style: { flex: 1, marginRight: j === 0 ? 8 : 0 },
                checked,
                children: (
                  <Text bold={checked}>
                    {item.label}
                    {item.value === OTHER_ID && isOtherSelected && (
                      <>
                        {" "}
                        - <Text italic>{otherResponse}</Text>
                      </>
                    )}
                  </Text>
                ),
              };

              return type === "checkbox" ? (
                <PDFCheckbox key={item.value} {...itemProps} />
              ) : (
                <PDFRadio key={item.value} {...itemProps} />
              );
            })}
          </View>
        );
      })}
    </>
  );
};
